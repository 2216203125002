.Toastify__toast-body {
  white-space: pre-line;
}

.navbar-light.bg-light .nav-link {
  color: #041433;
}

.navbar {
  background-color: #eff1f5;
  padding: 1.375rem 0;
  box-shadow: 0px 1px 2px 0px #dfe3ec;
}

.navbar-nav .nav-link {
  color: #041433;
  padding: 0 0 0.5rem 0;
  box-sizing: border-box;
  margin: 0 10px 0 0;
  font-weight: 400;
  border-bottom: 3px solid transparent;
}

.navbar .router-link-exact-active {
  color: #041433;
  border-bottom: 3px solid #5946A4;
}

.navbar-brand {
  margin-right: 20px;
}

.nav-tabs .nav-link {
  color: #041433;
  text-decoration: none;
}

.nav-tabs .nav-link:hover {
  color: #000104a1;
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: #000104a1;
  text-decoration: none;
}

.navbar-nav .btn {
  min-width: 145px;
}

.nav-user-profile {
  border-radius: 50% 50%;
  max-width: 3.785rem;
  height: auto;
  box-shadow: 0px 0px 4px 0px #b3bac7;
}

.nav-item.dropdown .dropdown-toggle {
  margin: 0;
}

.nav-item.dropdown .dropdown-name {
  font-weight: 600;
}

.nav-item.dropdown .router-link-exact-active {
  border: 0;
}

.nav-item.dropdown .dropdown-menu {
  box-shadow: 0px 0px 4px 0px #b3bac7;
}

.nav-item.dropdown .dropdown-header {
  border-bottom: 1px solid #b3bac7;
  font-size: 1rem;
  font-weight: 600;
  color: #041433;
}

.nav-item.dropdown .dropdown-item {
  border-bottom: 1px solid #b3bac7;
  padding: 0.55rem 1.5rem;
}

.nav-item.dropdown .dropdown-item .icon {
  margin-right: 8px;
  vertical-align: middle;
}

.nav-item.dropdown .dropdown-item:first-child:hover {
  background: inherit;
}

.nav-item.dropdown .dropdown-item:last-child {
  border-bottom: 0;
}

.navbar {
  padding: 1.375rem 0;
}

.nav-clock-separator {
  min-width: 0.1em;
  height: 72px;
  background-color: #dfe3ec;
  margin: 0 4px;
}

.nav-main-clock {
  background-color: #DADADA;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.nav-consumer-clock {
  background-color: #DADADA;
  width: 88px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

@media (min-width: 576px) {
  .container {
    max-width: inherit;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 2000px;
  }
}

/* previous was 1680px */
/* pre-previous was 1140px */

@media (max-width: 1199) {
  .navbar-nav .nav-item {
    margin-bottom: 1rem;
  }

  .navbar-nav .nav-item .nav-link {
    padding: 0 0 0 16px;
  }

  .nav-item .nav-link.router-link-exact-active {
    border-bottom: 3px solid transparent;
    border-left: 3px solid #5946A4;
    padding-left: 13px !important;
    vertical-align: middle;
  }

  .navbar-brand {
    margin-left: 2.5rem;
    vertical-align: top;
  }

  .navbar-toggler {
    margin-right: 2.5rem;
  }

  .navbar-nav {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .navbar-nav:first-child {
    margin-top: 1.5em;
  }

  .navbar-nav:last-child {
    background: white;
    box-shadow: 0 -1px 2px 0 #dfe3ec;
    margin: 1.5em 0 0 0;
    padding: 1.5em 2.5rem;
  }

  .navbar-nav:last-child li {
    margin-bottom: 1em;
  }

  .navbar-nav:last-child li .icon {
    margin-right: 1em;
    vertical-align: middle;
  }

  .navbar-nav:last-child li a {
    vertical-align: middle;
    color: #041433;
    text-decoration: none;
  }

  .navbar-nav .user-info img {
    margin-right: 1em;
  }

  .navbar-nav .btn-link {
    padding: 0;
    color: #041433;
    min-width: 0px;
  }
}

.nav-dashboard-dropdown-menu {
  margin: 0;
  cursor: pointer;
}

.nav-dashboard-dropdown-menu .dropdown-item {
  text-decoration: none;
}

.dropdown-profile:hover {
  text-decoration: none;
}

.logo {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzYgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PGZpbHRlciB4PSItNi45JSIgeT0iLTYuMiUiIHdpZHRoPSIxMTMuOSUiIGhlaWdodD0iMTEyLjUlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMSI+PGZlT2Zmc2V0IGR4PSIwIiBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSI+PC9mZU9mZnNldD48ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMSAgIDAgMCAwIDAgMSAgIDAgMCAwIDAgMSAgMCAwIDAgMC41IDAiIHR5cGU9Im1hdHJpeCIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dNYXRyaXhPdXRlcjEiPjwvZmVDb2xvck1hdHJpeD48ZmVNZXJnZT48ZmVNZXJnZU5vZGUgaW49InNoYWRvd01hdHJpeE91dGVyMSI+PC9mZU1lcmdlTm9kZT48ZmVNZXJnZU5vZGUgaW49IlNvdXJjZUdyYXBoaWMiPjwvZmVNZXJnZU5vZGU+PC9mZU1lcmdlPjwvZmlsdGVyPjwvZGVmcz48ZyBpZD0iUHJvamVjdC1TYW1wbGUtVUkiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJIb21lLUxvZ2dlZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0Ny4wMDAwMDAsIC0yOC4wMDAwMDApIj48ZyBpZD0iSGVhZGVyIj48ZyBpZD0iTG9nbyIgZmlsdGVyPSJ1cmwoI2ZpbHRlci0xKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjQ3LjAwMDAwMCwgMjguMDAwMDAwKSI+PHBhdGggZD0iTTMxLjA3NzIzMjQsMCBMMTcuOTk5NTI4NywwIEw0LjkxOTYzMTUyLDAgTDAuODc4MDkwMjU4LDEyLjM1OTU2MjUgQy0xLjU3OTczNzE0LDE5Ljk0OTAyODQgMS4yODcxNzk3OCwyNy45Mzc3NzIxIDcuNDE2OTQyMDksMzIuMzYwNjI0NCBMMTcuOTk3MzM1Miw0MCBMMjguNTgxMDE4NiwzMi4zNTk1NjI1IEMzNS4wNDA5MDQsMjcuNjk1NjU2OCAzNy40NjAzNDUsMTkuNTU5MzA3NiAzNS4xMzQxMjgyLDEyLjM5Nzc5MTIgQzM1LjEyNDI1NzQsMTIuMzY0ODcyIDMxLjA3NzIzMjQsMCAzMS4wNzcyMzI0LDAiIGlkPSJGaWxsLTQiIGZpbGw9IiMwNDE0MzMiPjwvcGF0aD48cG9seWdvbiBpZD0iRmlsbC02IiBmaWxsPSIjRUZGMUY1IiBwb2ludHM9IjE4IDAgMjIuMDEyOTU0NyAxMi42MDQ3OTE4IDM1IDEyLjYwNDc5MTggMjQuNDkyOTgwMiAyMC4zOTUyMDgyIDI4LjUwNzAxOTggMzMgMTggMjUuMjEwNjY4MiA3LjQ5Mjk4MDIyIDMzIDExLjUwNTkzNDkgMjAuMzk1MjA4MiAxIDEyLjYwNDc5MTggMTMuOTg3MDQ1MyAxMi42MDQ3OTE4Ij48L3BvbHlnb24+PC9nPjwvZz48L2c+PC9nPjwvc3ZnPg==");
  width: 2rem;
  height: 2.25rem;
  background-repeat: no-repeat;
  background-size: cover;
}

footer {
  background: #eff1f5;
  box-shadow: 0px -1px 2px 0px #dfe3ec;
  min-height: 50px;
  text-align: center;
  padding: 32px;
}

footer .logo {
  margin: 0 auto 10px auto;
}

.bo-footer-text {
  height: 2px;
  font-size: 10px;
}

.hero {
  max-width: 500px;
  margin: 0 auto;
}

.hero p.lead {
  margin-bottom: 10rem;
  font-size: 1.45rem;
}

.hero p.lead a {
  font-weight: 500;
}

.hero .app-logo {
  max-width: 7.5rem;
}

.next-steps {
  padding: 0 32px;
}

.next-steps h2 {
  margin-bottom: 4rem;
}

.next-steps .row {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .row {
    margin-bottom: 0;
  }

  .next-steps h6 {
    margin-top: 1.5rem;
  }
}

pre {
  width: 100%;
}

.profile-header {
  text-align: center;
  margin-bottom: 3rem;
}

.profile-header .lead {
  font-size: 1.25rem;
}

.profile-header .profile-picture {
  box-shadow: 0px 0px 4px 0px #b3bac7;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .profile-header {
    text-align: left;
  }

  .profile-header .profile-picture {
    margin-bottom: inherit;
  }
}

.result-block-container {
  position: relative;
  min-height: 300px;
}

.result-block {
  top: -10px;
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
}

.result-block.show {
  opacity: 1;
  top: 0px;
}

@media only screen and (min-width: 768px) {
  .result-block {
    min-width: 700px;
  }
}

html {
  font-size: 16px;
}

body {
  color: #041433;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h6.muted {
  color: #6c757d;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

a {
  color: #0066f9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.btn-primary {
  background-color: #0066f9;
  border: 1px solid #005ce0;
}

.btn {
  border-radius: 0.1rem;
}

.purple-background {
  background-color: #5946A4
}

.purple-background:focus {
  background-color: #5946A4;
  border-color: #0066f9;
  box-shadow: 0 0 0 .2rem #8988C5 !important
}

.bo-input {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 700;
  font-size: 14px;
  background-color: #DADADA;
  color: #041433;
  border-radius: 0.5rem;
  border-width: 0px;
  height: 30px;
  width: 100%;
  padding-left: 10px
}

.bo-input:read-only {
  background-color: #e9ecef;
  color: #5f5f5f;
}

.bo-input:disabled {
  background-color: #e9ecef;
  color: #5f5f5f;
}

.bo-input-right {
  text-align: "right";
}

.bo-input-optional {
  border-color: #6C757D;
}

.bo-input::placeholder {
  color: #ababab;
}

.bo-input-error {
  background-color: #FFD1D1;
}

.bo-eye-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #979797;
  width: 50px;
  height: 30px;
}

.bo-eye-wrapper:hover {
  color: gray;
}

.bo-validation-error {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 400;
  font-size: 13px;
  color: red;
  text-align: left;
  padding-left: 15px;
}

.bo-text {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 16px;
}

.bo-text-bold {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 700;
  font-size: 16px;
}

.bo-text-xs {
  font-size: 8px !important;
}

.bo-text-sm {
  font-size: 12px !important;
}

.bo-text-sm-bold {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 12px;
  font-weight: 500;
}

.bo-text-md {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 14px;
}

.bo-larger {
  font-size: 18px;
  font-weight: 700;
}

.bo-larger-x2 {
  font-size: 22px;
  font-weight: 700;
}

.bo-text-red {
  color: red;
}

.bo-text-black {
  color: black;
}

.bo-text-light-gray {
  color: lightgray;
}

.bo-link {
  color: #5946A4 !important;
  text-decoration: underline !important;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
}

.bo-link:hover {
  color: #9f9cac !important;
}

.bo-black-link {
  color: black !important;
  cursor: pointer;
}

.bo-black-link:hover {
  text-decoration: underline !important;
}

.bo-button-100 {
  width: 100px;
}

.bo-button-170 {
  width: 170px;
}

.bo-link:active {
  background-color: #e5e3ec;
  border-radius: 5px;
}

.bo-header {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 28px;
}

.bo-button {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 16px;
  background-color: #5946A4;
  border-radius: 0.7rem;
  color: white;
  outline: none !important;
}

.bo-button:hover {
  background-color: #9489c1;
}

.bo-button-gray {
  background-color: #6C757D !important;
}

.bo-button-gray:hover {
  background-color: #909395 !important;
}

.bo-button-link {
  color: white;
  text-decoration: none;
}

.bo-button-link:hover {
  color: #8988C5;
  text-decoration: none;
}

.bo-button-link-no-hover {
  color: white;
  text-decoration: none;
}

.bo-date-time-picker {
  width: 250px;
  min-width: 250px;
  padding-left: 10;
  padding-right: 10;
}

.bo-date-time-picker .react-datetime-picker__wrapper {
  border: none;
}

.bo-date-picker {
  padding-left: 10;
  padding-right: 10;
}

.bo-date-picker-modal-container {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bo-max-w-1 {
  max-width: 1px;
}

.bo-w-30 {
  width: 30px;
}

.bo-w-32 {
  width: 32px;
}

.bo-w-40 {
  width: 40px;
}

.bo-w-50 {
  width: 50px;
}

.bo-w-60 {
  width: 60px;
}

.bo-min-w-60 {
  min-width: 60px;
}

.bo-w-70 {
  width: 70px;
}

.bo-w-80 {
  width: 80px;
}

.bo-min-w-80 {
  min-width: 80px;
}

.bo-w-100 {
  width: 100px;
}

.bo-min-w-100 {
  min-width: 100px;
}

.bo-max-w-100 {
  max-width: 100px;
}

.bo-w-120 {
  width: 120px;
}

.bo-min-w-120 {
  min-width: 120px;
}

.bo-w-125 {
  width: 125px !important;
}

.bo-w-150 {
  width: 150px;
}

.bo-min-w-150 {
  min-width: 150px;
}

.bo-w-180 {
  width: 180px;
}

.bo-w-200 {
  width: 200px;
}

.bo-min-w-200 {
  min-width: 200px;
}

.bo-w-220 {
  width: 220px;
}

.bo-min-w-220 {
  min-width: 220px;
}

.bo-w-225 {
  width: 225px;
}

.bo-w-240 {
  width: 240px;
}

.bo-min-w-240 {
  min-width: 240px;
}

.bo-w-250 {
  width: 250px !important;
}

.bo-w-280 {
  width: 280px;
}

.bo-min-w-280 {
  min-width: 280px;
}

.bo-w-300 {
  width: 300px;
}

.bo-w-400 {
  width: 400px !important;
}

.bo-min-w-400 {
  min-width: 400px;
}

.bo-max-w-400 {
  max-width: 400px;
}

.bo-w-500 {
  width: 500px !important;
}

.bo-w-600 {
  width: 600px;
}

.bo-w-640 {
  width: 640px;
}

.bo-h-30 {
  height: 30px;
}

.bo-h-50 {
  height: 50px;
}

.bo-max-h-80 {
  max-height: 80px;
}

.bo-min-h-100 {
  min-height: 100px;
}

.bo-h-60-fixed {
  max-height: 60px;
  min-height: 60px;
  height: 60px;
}

.bo-h-150-fixed {
  max-height: 150px;
  min-height: 150px;
  height: 150px;
}

.bo-h-200-fixed {
  max-height: 200px;
  min-height: 200px;
  height: 200px;
}

.bo-resize-none {
  resize: none;
}

.bo-button:focus {
  background-color: #5946A4;
  box-shadow: 0 0 0 .1rem #8988C5 !important
}

.bo-button-inline {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  height: 30px;
  font-weight: 500;
  font-size: 13px;
  background-color: #5946A4;
  border-radius: 0.5rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.bo-button-inline:focus {
  background-color: #5946A4;
  box-shadow: 0 0 0 .2rem #8988C5 !important
}

.bo-button-inline-link {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 14px;
  color: #5946A4;
  text-decoration: none;
}

.bo-button-inline-link:hover {
  color: #5946A4;
  text-decoration: underline;
}

.bo-button-inline-link-disabled:disabled {
  color: #000000;
  opacity: 1;
}

.bo-inline-error {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  margin-left: 10px;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  color: red;
  border-radius: 0.5rem;
}

.bo-inline-error-large {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  margin-left: 10px;
  height: 30px;
  font-weight: 500;
  font-size: 15px;
  color: red;
  border-radius: 0.5rem;
}

.bo-password-policy {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 13px;
  color: #6C757D;
  border-radius: 0.5rem;
}

.bo-search {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 700;
  font-size: 14px;
  background-color: #DADADA;
  color: #6C757D;
  border-radius: 0.5rem;
  border: none;
  height: 30px;
  width: 200px;
  background-image: url('search_gray.png');
  background-size: 20px 20px;
  background-position: 5px 5px;
  background-repeat: no-repeat;
  padding-left: 30px;
}

.bo-search::placeholder {
  color: #ababab;
}

.bo-consumer-quick-access {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 700;
  font-size: 14px;
  background-color: #DADADA;
  color: #6C757D;
  border-radius: 0.5rem;
  border: none;
  height: 30px;
  background-image: url('search_gray.png');
  background-size: 20px 20px;
  background-position: 5px 5px;
  background-repeat: no-repeat;
  padding-left: 30px;
}

.bo-consumer-quick-access::placeholder {
  color: #ababab;
}

.bo-consumer-quick-access-loading {
  background-image: none;
}

.bo-consumer-quick-access-spinner {
  position: absolute;
  top: 7px;
  left: 7px;
}

.bo-pagination {
  justify-content: right;
  display: flex;
  vertical-align: middle;
}

.bo-pagination p {
  margin: 0px;
}

.bo-filter {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 700;
  font-size: 14px;
  background-color: #DADADA;
  color: #041433;
  border-radius: 0.5rem;
  border: none;
  height: 30px;
  width: 200px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23041433' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.bo-filter::placeholder {
  color: #ababab;
}

.bo-filter:disabled {
  background-color: #e9ecef;
  color: #5f5f5f;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%235f5f5f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.bo-filter:focus {
  background-color: #ffffff;
}

.bo-select {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 700;
  font-size: 14px;
  background-color: #DADADA;
  color: #041433;
  border-radius: 0.5rem;
  border: none;
  height: 30px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23041433' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.bo-select:disabled {
  background-color: #e9ecef;
  color: #5f5f5f;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%235f5f5f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.bo-select:focus {
  background-color: #ffffff;
}

.bo-multiple-select-filter {
  font-family: -apple-system, system-ui, BlinkMacSystemFont !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  background-color: #DADADA;
  color: #6C757D !important;
  border-radius: 0.7rem !important;
  border-color: #6C757D !important;
  border-width: 1px;
  height: 30px;
  width: 240px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.bo-same-line {
  display: flex;
  justify-content: left;
  align-items: center;
}

.bo-same-line-top {
  display: flex;
  justify-content: left;
  align-items: top;
}

.bo-search-column {
  display: flex;
  justify-content: right;
  align-items: center;
}

.bo-dropdown {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 16px;
  background-color: #5946A4 !important;
  border-radius: 0.7rem;
  color: white;
}

.bo-dropdown:focus {
  background-color: #5946A4 !important;
  box-shadow: 0 0 0 .2rem #8988C5 !important
}

.bo-dropdown:disabled {
  background-color: #8988C5 !important;
}

.bo-dropdown:hover {
  background-color: #8988C5 !important;
}

.bo-dropdown-menu {
  background-color: #5946A4 !important;
  color: white !important;
  border-radius: 0.7rem;
}

.bo-dropdown-menu-gray {
  background-color: #6C757D !important;
  color: white !important;
  border-radius: 4px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.bo-dropdown-menu-item {
  background-color: #5946A4 !important;
  color: white !important;
}

.bo-dropdown-menu-item:hover {
  background-color: #5946A4 !important;
  color: #8988C5 !important;
}

.bo-dropdown-menu-item:disabled {
  color: #8988C5 !important;
}

.bo-dropdown-menu-item-inline-gray {
  background-color: #6C757D !important;
  color: white !important;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 13px;
  padding-left: 13px;
  border-bottom: thin solid #909395;
  height: 25px;
}

.bo-dropdown-menu-item-inline-gray:hover {
  color: #909395 !important;
}

.bo-dropdown-menu-item-inline-gray:disabled {
  color: #909395 !important;
}

.bo-dropdown-menu-item-inline-gray:last-child {
  border-bottom: none
}

.bo-sidebar-button {
  width: 100%;
  background-color: transparent;
  color: #000000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 600;
  text-align: start;
  border: none;
  padding-left: 20%;
  border-radius: 0;
}

.bo-sidebar-button:hover, .bo-sidebar-button:active {
  background-color: #8988C5 !important;
  color: white !important;
}

.bo-sidebar-button:disabled {
  background-color: transparent;
  color: #000000;
  opacity: 0.2;
}

.bo-sidebar-button-active {
  background-color: #5946A4;
  color: white;
}

.bo-sidebar-accordion {
  background-color: transparent;
  color: #000000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-weight: 600;
  text-align: start;
  border: none;
  padding-left: 0;
  border-radius: 0;
  width: 100%;
  display: flex;
}

.bo-sidebar-accordion-icon {
  width: 20%;
  text-align: center;
  color: black;
}

.bo-sidebar-accordion:hover {
  background-color: transparent !important;
  color: #000000 !important;
}

.bo-sidebar-accordion:disabled {
  background-color: transparent;
  color: #000000;
  opacity: 0.2;
}

.bo-file-chooser::file-selector-button {
  background-color: #5946A4;
  color: white;
  border: none;
}

.bo-inline-badge {
  font-size: 12px
}

.bo-badge {
  font-size: 14px
}

.bo-light-green {
  color: rgb(37, 210, 53) !important;
}

.bo-badge-bg-light-green {
  background-color: rgb(37, 210, 53);
  color: white
}

.bo-bg-light-green {
  background-color: rgb(37, 210, 53) !important;
}

.bo-badge-bg-border-red {
  background-color: white !important;
  color: rgb(209, 30, 54);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(209, 30, 54);
}

.bo-badge-bg-border-green {
  background-color: white;
  color: rgb(37, 154, 53);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(37, 154, 53);
}

.bo-badge-bg-border-yellow {
  background-color: white;
  color: rgb(254, 182, 13);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(254, 182, 13);
}

.bo-tabs {
  border-style: solid;
  border-width: 1px;
  border-color: #DEE2E6;
}

.bo-pointer {
  cursor: pointer;
}

.bo-mobile {
  max-width: 400px;
}

.bo-checkbox {
  width: 20px;
  height: 20px;
  position: static;
}

.bo-border-container {
  border-style: solid;
  border-width: thin;
  border-color: #E0E0E0;
  padding: 10px;
  margin-top: 3;
  margin-bottom: 3;
  border-radius: 5;
}

.bo-border-bottom {
  border-style: solid;
  border-width: 0;
  border-bottom-width: thin;
  border-color: #E0E0E0;
}

.bo-flex-1-left {
  display: flex;
  align-items: center;
  justify-content: left;
  flex: 1;
  height: 100%;
}

.bo-flex-1-right {
  display: flex;
  align-items: center;
  justify-content: right;
  flex: 1;
  height: 100%;
}

.bo-td-w-500 {
  width: 500px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
}

.bo-inline-right-comment {
  font-size: 14px;
  margin-left: 10px;
}

.bo-table-border {
  border-left: 0.5px solid #E0E0E0 !important;
  border-right: 0.5px solid #E0E0E0 !important;
  border-bottom: 0.5px solid #E0E0E0 !important;
}

.bo-table-row-bg-yellow {
  background-color: #FFF000;
}

.bo-table-hover-bg {
  background-color: #efefef7a;
}

.bo-table-alt-hover-bg {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.05);
}

.bo-table-row-hover:hover {
  background-color: #efefef7a;
}

.bo-table-row-alt-hover-bg>div {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.05);
}

.bo-table-active-bg {
  background-color: rgba(37, 210, 53, 0.4) !important;
}

.bo-custom-table-hover:hover {
  background-color: #efefef7a;
}

.bo-table-group-cell {
  background-color: #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bo-table-cell {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-transform: none;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
}

.bo-modal-form {
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bo-table-header-container {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bo-table-header-container::-webkit-scrollbar {
  display: none;
}

.bo-table-group-container {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bo-table-group-container::-webkit-scrollbar {
  display: none;
}

.bo-table-container::-webkit-scrollbar {
  position: absolute;
  -webkit-appearance: none;
  background-color: #e3e3e3;
}

.bo-table-container::-webkit-scrollbar:vertical {
  width: 10px;
}

.bo-table-container::-webkit-scrollbar:horizontal {
  height: 10px;
}

.bo-table-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 2px solid #e3e3e3;
  background-color: #999;
}

.bo-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

.bo-table-highlight-text {
  padding: .1875em 0;
  background-color: #FFFF00 !important;
}

.form-check-input {
  margin-right: 8px;
}

.bo-card-adhoc-bg-color {
  background-color: #f6f6f6 !important;
}

.bo-switch-scale {
  width: 0px;
  transform: scale(1.5) translate(25%, 0%);
}

.bo-bt-element-container {
  height: 37px;
  margin-bottom: 8px;
  border-color: #DADADA;
  border-style: solid;
  border-width: 1px;
  background-color: #DADADA;
  border-radius: 10px;
}

.bo-bt-element-container-invalid {
  border-color: red;
}

.bo-bt-element-w-100px {
  width: 100px;
}

.bo-card-form-default-input {
  width: 100%;
  height: 37px;
  margin-bottom: 8px;
  border-color: #DADADA;
  border-style: solid;
  border-width: 1px;
  background-color: #DADADA;
  border-radius: 10px;
  padding: 8px;
  color: #000000;
  font-size: 16px;
}

.bo-card-form-default-input-invalid {
  border-color: red;
}

.bo-card-form-default-input::placeholder {
  color: #979797;
}

.bo-card-form-default-input::-webkit-input-placeholder {
  color: #979797;
}

.bo-card-form-default-input:-moz-placeholder {
  color: #979797;
}

.bo-card-form-default-input::-ms-placeholder {
  color: red;
}

.bo-card-form-default-input:focus {
  outline: none;
}

.bo-card-form-name-input {
  width: 49%;
}

.bo-card-form-city-input {
  width: 48%;
}

.bo-card-form-state-input {
  width: 20%;
}

.bo-card-form-zip-input {
  width: 30%;
}

.bo-card-form-default-input-readonly {
  background-color: #EFEFEF;
  border-color: #EFEFEF;
}

.bo-timestamp-day-indicator {
  position: absolute;
  margin: 0;
  padding: 0;
  top: -4px;
  right: 6px;
  font-size: 10px;
  color: red;
}

/* =============================== COMMON =============================== */

.bo-common-inline-button {
  color: #5946A4 !important;
  background-color: transparent !important;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  padding: 0;
  margin: 0;
}

@media (min-width: 1600px) {
  .bo-common-inline-button {
    font-size: 14px;
  }
}

.bo-common-iframe-container {
  border: #ccc;
  border-width: 1px;
  border-style: solid;
  border-left-width: 0;
}

/* =============================== CONSUMER =============================== */

.bo-consumer-resize-handle-vertical {
  width: 2px !important;
  background-color: #DADADA !important;
}

.bo-consumer-resize-handle-horizontal {
  height: 2px !important;
  background-color: #DADADA !important;
}

.bo-consumer-text-xsm {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 10px !important;
  padding: 0;
  margin: 0;
}

.bo-consumer-text-sm {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 11px;
  padding: 0;
  margin: 0;
}

.bo-consumer-text {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 13px;
  padding: 0;
  margin: 0;
}

.bo-consumer-text-bold {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 13px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.bo-consumer-text-md {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.bo-consumer-text-xl {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.bo-consumer-text-xl-bold {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.bo-consumer-text-xxl-bold {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 22px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.bo-consumer-nav {
  cursor: pointer;
  font-size: 13px;
}

.bo-consumer-nav-link {
  margin-top: 0.25rem !important;
  margin-right: 0.25rem;
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
  padding-right: .5rem !important;
  padding-left: .5rem !important;
  border-radius: 0 !important;
  border: 1px solid #DEE2E6 !important;

  /* iOS Safari */
  -webkit-touch-callout: none;
  /* Safari */
  -webkit-user-select: none;
  /* Konqueror HTML */
  -khtml-user-select: none;
  /* Old versions of Firefox */
  -moz-user-select: none;
  /* Internet Explorer/Edge */
  -ms-user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  user-select: none;
}

.bo-consumer-nav-link-active {
  background-color: #EFEFEF;
}

.bo-consumer-sub-link {
  color: #5946A4 !important;
  background-color: transparent !important;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.bo-consumer-sub-link-active {
  font-weight: 900;
}

.bo-consumer-tabs {
  border-style: solid;
  border-width: 1px;
  border-color: #DEE2E6;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bo-consumer-dropdown-toggle {
  font-size: 13px;
  font-weight: 600;
  padding: 2px 16px;
  color: white;
  border: none;
  background-color: #5946A4 !important;
  border-radius: 4px;
}

.bo-consumer-dropdown-toggle:hover {
  background-color: #9489c1 !important;
}

.bo-consumer-dropdown-menu {
  border-radius: 4px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.bo-consumer-dropdown-menu-item {
  font-size: 13px;
}

.bo-consumer-dropdown-menu-item:active {
  background-color: #5946A4;
}

.popover {
  --bs-popover-max-width: 640px;
}

.tooltip.show {
  --bs-tooltip-opacity: 1;
}

.tooltip-arrow-red .tooltip-arrow::before {
  border-right-color: red !important;
}

.bo-consumer-popover-form-toggle {
  border: none !important;
  background: none !important;
  padding: 0;
  margin: 0;
  color: #000 !important;
  display: flex;
  align-items: center;
  gap: 8px;
}

.bo-consumer-popover-toggle-form-name {
  width: calc(100% - 116px);
}

.bo-consumer-popover-form-toggle-title {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: start;
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 22px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.bo-consumer-popover-form-toggle .bo-consumer-popover-form-toggle-icon {
  padding: 0;
  margin: 0;
  border: none !important;
  background: none !important;
  visibility: hidden;
}

.bo-consumer-popover-form-toggle:hover .bo-consumer-popover-form-toggle-icon {
  visibility: visible;
}

.bo-consumer-popover-form-toggle-icon-active {
  visibility: visible !important;
}

.bo-consumer-popover-form-menu {
  border-radius: 4px;
  padding: 8px 12px 12px 12px !important;
}

.bo-consumer-popover-form-menu-label {
  font-size: 10px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.bo-consumer-popover-form-menu-input {
  font-size: 13px;
  font-weight: 500;
  padding: 4px;
  box-shadow: none !important;
  border-radius: 4px;
}

.bo-consumer-popover-form-menu-search {
  font-size: 13px;
  font-weight: 500;
  padding: 4px;
  padding-left: 26px;
  box-shadow: none !important;
  border-radius: 4px;
  background-image: url('search_gray.png');
  background-size: 16px 16px;
  background-position: 5px 5px;
  background-repeat: no-repeat;
}

.bo-consumer-popover-form-content-box {
  border: 1px solid #dee2e6;
  height: 100px;
  border-radius: 4px;
  margin-top: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bo-consumer-popover-form-content-button {
  border: none !important;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 0.25rem 4px;
  color: #000;
}

.bo-consumer-popover-form-content-button:hover {
  background-color: #F8F9FA;
  color: #000;
}

.bo-consumer-popover-form-content-button:focus:active {
  color: white;
  background-color: #5946A4;
}

.bo-consumer-button {
  color: #fff;
  background-color: #5946A4;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 16px;
  border-radius: 4px;
}

.bo-consumer-button:hover,
.bo-consumer-button:focus {
  background-color: #9489c1;
}

.bo-consumer-inline-badge {
  font-size: 10px;
}

.bo-consumer-inline-badge-hover:hover {
  opacity: 0.8;
}

.bo-consumer-accordion {
  border-top: 1px solid #DADADA;
}

.bo-consumer-accordion-bottom-border {
  border-bottom: 1px solid #DADADA;
}

.bo-consumer-accordion-top-section {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  background-color: transparent !important;
  color: #000 !important;
  gap: 4px;
  border: none !important;
}

.bo-consumer-accordion-icon {
  width: 20px;
}

.bo-consumer-accordion-content {
  padding: 0px 10px 20px 30px;
}

.bo-consumer-input-label {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 10px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.bo-consumer-text-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent !important;
  margin: 0;
  padding: 0;
  border: none !important;
}

.bo-consumer-text-input {
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 0;
  padding: 0;
}

.bo-consumer-text-input:read-only {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.bo-consumer-text-input:focus {
  outline: none;
  box-shadow: none;
}

.bo-consumer-text-bottom-border {
  width: 100%;
  height: 1px;
  background-color: transparent;
}

.bo-consumer-text-bottom-border-active {
  width: 100%;
  height: 1px;
  background-color: #000;
}

.bo-consumer-text-input-container .bo-consumer-input-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 40px;
  gap: 5px;
  margin-left: 20px;
  visibility: hidden;
}

.bo-consumer-text-input-container:hover .bo-consumer-input-button-container {
  visibility: visible;
}

.bo-consumer-input-button-container-active {
  visibility: visible !important;
}

.bo-consumer-text-input-container .bo-consumer-trash-button-container {
  display: flex;
  margin-left: 10px;
  visibility: hidden;
}

.bo-consumer-text-input-container:hover .bo-consumer-trash-button-container {
  visibility: visible !important;
}

.bo-consumer-input-button-icon {
  font-size: 16px;
  border: none !important;
  background: none !important;
  padding: 0;
  margin: 0;
}

.bo-consumer-multiline-text-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent !important;
  margin: 0;
  padding: 0;
  border: none !important;
}

.bo-consumer-multiline-text-input {
  min-height: auto !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 0;
  padding: 0;
  resize: none;
}

.bo-consumer-multiline-text-input:focus {
  outline: none;
  box-shadow: none;
}

.bo-consumer-multiline-text-input-container .bo-consumer-input-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 40px;
  gap: 5px;
  margin-left: 20px;
  visibility: hidden;
}

.bo-consumer-multiline-text-input-container:hover .bo-consumer-input-button-container {
  visibility: visible;
}

.bo-consumer-select-input {
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 0;
  padding: 0;
  background: none !important;
  color: #000 !important;
  text-align: left;
  display: flex;
  align-items: center;
}

.bo-consumer-select-input-icon {
  visibility: hidden;
}

.bo-consumer-select-input:hover .bo-consumer-select-input-icon {
  margin-left: auto;
  visibility: visible;
}

.bo-consumer-select-input-no-img {
  background-image: none !important;
}

.bo-consumer-select-input:read-only {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.bo-consumer-select-input:focus {
  outline: none;
  box-shadow: none;
}

.bo-consumer-select-input:disabled {
  background-color: transparent !important;
}

.bo-consumer-base-button {
  border: none !important;
  background: none !important;
  padding: 0;
  margin: 0;
}

.bo-error-border-container {
  border-style: solid;
  border-width: thin;
  border-color: #E0E0E0;
  padding: 10px;
  margin-top: 3;
  margin-bottom: 3;
  border-radius: 5;
  background-color: #FFE8E8;
}

.bo-consumer-link {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 13px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: #5946A4;
}

.bo-consumer-mobile-platform {
  height: 24px;
  display: flex !important;
  align-items: center !important;
  gap: 4px;
}

.bo-consumer-bt-element-container {
  height: 31px;
  margin-bottom: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e6;
  border-radius: 4px;
}

.bo-consumer-bt-element-container-focused {
  border-color: #86b7fe;
}

.bo-consumer-bt-element-container-invalid {
  border-color: red !important;
}

.bo-consumer-card-form-default-input-invalid {
  border-color: red !important;
}

.bo-consumer-switch-scale {
  width: 0px;
  transform: scale(1.2) translate(20%, 0%);
}

.bo-consumer-card-form-city-input {
  width: 48%;
}

.bo-consumer-card-form-state-input {
  width: 20%;
}

.bo-consumer-card-form-zip-input {
  width: 30%;
}

.bo-consumer-card-form-default-input-readonly {
  background-color: #EFEFEF;
  border-color: #EFEFEF;
}

.bo-consumer-notification-iframe-wrapper {
  width: 300px;
  height: 200px;
  padding: 0;
  overflow: hidden;
  border: 1px solid #dee2e6,
}

.bo-consumer-notification-iframe {
  width: 375px;
  height: 250px;
  border: 0;
  transform: scale(0.8);
  transform-origin: 0 0;
}

@media (min-width: 1600px) {
  .bo-consumer-text-sm {
    font-size: 12px;
  }

  .bo-consumer-text {
    font-size: 14px;
  }

  .bo-consumer-text-bold {
    font-size: 14px;
  }

  .bo-consumer-text-xl {
    font-size: 19px;
  }

  .bo-consumer-text-xl-bold {
    font-size: 19px;
  }

  .bo-consumer-text-xxl-bold {
    font-size: 23px;
  }

  .bo-consumer-nav {
    font-size: 14px;
  }

  .bo-consumer-dropdown-toggle {
    font-size: 14px;
  }

  .bo-consumer-dropdown-menu-item {
    font-size: 14px;
  }

  .bo-consumer-popover-form-toggle-title {
    font-size: 23px;
  }

  .bo-consumer-popover-form-menu-label {
    font-size: 11px;
  }

  .bo-consumer-popover-form-menu-input {
    font-size: 14px;
  }

  .bo-consumer-button {
    font-size: 14px;
  }

  .bo-consumer-inline-badge {
    font-size: 11px;
  }

  .bo-consumer-input-label {
    font-size: 11px;
  }

  .bo-consumer-text-input {
    font-size: 14px;
  }

  .bo-consumer-multiline-text-input {
    font-size: 14px;
  }

  .bo-consumer-select-input {
    font-size: 14px;
  }

  .bo-consumer-link {
    font-size: 14px;
  }
}